import React from "react";
import "./../Slick/vendor/slick-theme.scss";
import "./../Slick/vendor/slick.scss";
import {
  ClientsListWrapperStyled,
  ClientsListStyled,
  ClientsListItemStyled,
  ClientsListItemMedia,
  ClientsListItemMediaImg,
  MobileWrapper,
} from "./styles";
import { ImageProperties } from "./../../interfaces/IImage";

export interface IClientItem {
  items: { 
    image: Pick<ImageProperties, 'sourceUrl' | 'altText'>
  }[];
  settings: object;
}

const Clients: React.FC<IClientItem> = ({ items }) => {
  return (
    <ClientsListWrapperStyled>
      <MobileWrapper>
        <ClientsListStyled>
          {items.map((item, index) => (
            <ClientsListItemStyled key={index}>
              <ClientsListItemMedia>
                <ClientsListItemMediaImg
                  src={item.image.sourceUrl}
                  alt={item.image.altText}
                  loading="lazy"
                  width="150"
                  height="50"
                />
              </ClientsListItemMedia>
            </ClientsListItemStyled>
          ))}
        </ClientsListStyled>
        <ClientsListStyled $hideOnDesktop={true} >
          {items.map((item, index) => (
            <ClientsListItemStyled key={index}>
              <ClientsListItemMedia>
                <ClientsListItemMediaImg
                  src={item.image.sourceUrl}
                  alt={item.image.altText}
                  loading="lazy"
                  width="150"
                  height="50"
                />
              </ClientsListItemMedia>
            </ClientsListItemStyled>
          ))}
        </ClientsListStyled>
      </MobileWrapper>
    </ClientsListWrapperStyled>
  );
};

export default Clients;

import React from "react";

import Container from "./../../components/Container";
import Section from "./../../components/Section";
import Inner from "./../../components/Inner";

import Uvp from "./../../components/Uvp";
import { Heading5 } from "./../../components/Typography";
import { IFeature } from "../../interfaces/IFeature";
import HighSubheading from "../../components/HighSubheading";
import styled from "styled-components";
import { media } from "../../common/MediaQueries";

const MessageWrapper = styled.div`
  margin-top: 20px; 

  @media ${media.tablet} { 
    margin-top: 60px;
  }
`

interface Props { 
  block: { 
    heading: string 
    items: IFeature[]
    subheading?: string
  }
  divHeading?: boolean
  index: number 
}

const FlexCtaSection = ({ block, divHeading, index }: Props) => {
  return (
    <Container pt={["50px", "145px"]}>
      <Inner isSmall>
        <Section
          pbHead={["58px", "2.375rem"]}
          ptBody={["", "2.5rem"]}
          heading={
            <Heading5
              as={divHeading ? 'div' : 'h2'}
              fontWeight="600"
              dangerouslySetInnerHTML={{ __html: block.heading }}
            />
          }
        >
          <Uvp items={block.items} />
          {block.subheading &&
            <MessageWrapper>
              <HighSubheading
                text={block.subheading}
              />
            </MessageWrapper>
          }
        </Section>
      </Inner>
    </Container>
  );
};

export default FlexCtaSection;

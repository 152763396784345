import styled from 'styled-components';

import { media, customMedia } from '../../common/MediaQueries';
import { transition } from '../../common/mixins';

const ScrollToIconWrapperStyled = styled.div`
  background-image: linear-gradient(137deg, #012147 0%, #38b1eb 100%);
  border-radius: 36.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  width: 32px;
  height: 32px;

  @media ${customMedia.maxPhone} {
    order: 2;
  }

  @media ${media.phone} {
    width: 50px;
    height: 50px;
    margin-bottom: 12px;
  }
`;

const ScrollToLabelStyled = styled.div`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.light};

  @media ${customMedia.maxPhone} {
    order: 1;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
`;

const ScrollToIconStyled = styled.div`
  display: flex;
  ${transition}

  svg {
    width: 11px;
    height: 18px;

    @media ${media.phone} {
      width: 18px;
      height: 28px;
    }
  }
`;

const ScrollToStyled = styled.a`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    ${ScrollToIconStyled} {
      transform: translateY(0.2rem);
    }
  }
`;

export { ScrollToStyled, ScrollToIconWrapperStyled, ScrollToLabelStyled, ScrollToIconStyled };

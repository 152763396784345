import React from "react";

import { DividerStyled, DividerItemStyled } from "./styles";

interface IProps {
  isMobileFullwidth?: boolean;
  isLarge?: boolean;
  isWhite?: boolean;
}

const Divider: React.FC<IProps> = ({ isMobileFullwidth, isLarge, isWhite }) => (
  <DividerStyled {...{ isMobileFullwidth, isLarge, isWhite }}>
    <DividerItemStyled />
  </DividerStyled>
);

export default Divider;

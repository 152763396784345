import React from "react";

import {
  ScrollToStyled,
  ScrollToIconWrapperStyled,
  ScrollToLabelStyled,
  ScrollToIconStyled,
} from "./styles";
import ScrollDownIcon from "./../../assets/svg/ScrollDown";
import { Typo3 } from "./../Typography";

interface IProps {
  title: string;
  scrollTarget?: string;
}

const ScrollTo: React.FC<IProps> = ({ title, scrollTarget }) => {
  return (
    <ScrollToStyled aria-label="Scroll down" href={`/#${scrollTarget}`}>
      <ScrollToIconWrapperStyled>
        <ScrollToIconStyled>
          <ScrollDownIcon />
        </ScrollToIconStyled>
      </ScrollToIconWrapperStyled>
      <ScrollToLabelStyled>
        <Typo3>{title}</Typo3>
      </ScrollToLabelStyled>
    </ScrollToStyled>
  );
};

export default ScrollTo;

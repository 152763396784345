import { ImageFile } from '../interfaces/IImageFile';

interface Options {
  width?: number;
  height?: number;
}

export function getCloudinarySrc(image: ImageFile, options?: Options) {
  const { width, height } = options || {};

  return `https://res.cloudinary.com/drhocurzh/image/upload/f_auto${width ? `,w_${width}` : ''}${
    height ? `,h_${height}` : ''
  }/cms/${image.localFile.name}.${image.localFile.extension}`;
}

export function getCloudinarySrcSet(image: ImageFile, sizes: number[]) {
  return sizes.reduce((acc, size) => {
    return `${acc}${acc !== '' ? ', ' : ''}${getCloudinarySrc(image, { width: size })} ${size}w`;
  }, '');
}

import React from "react";

import {
  TextColumnStyled,
  TextColumnItemStyled,
  TextColumnItemContentStyled,
  TextColumnItemHeadingStyled,
  TextColumnItemCtaStyled,
  TextColumnItemTextStyled,
} from "./styles";
import ButtonLink from "./../ButtonLink";
import { Heading7 } from "./../Typography";

interface IProps {
  heading: string;
  ctaUrl?: string;
  ctaText?: string;
  text: string;
  isRotated?: boolean;
  outline?: boolean
}

const TextColumn: React.FC<IProps> = ({
  heading,
  ctaUrl,
  ctaText,
  text,
  isRotated,
  outline,
}) => {
  return (
    <>
      {!isRotated ? (
        <TextColumnStyled flexDirection={["column", "row"]}>
          <TextColumnItemStyled width={["100%", "48%"]}>
            <TextColumnItemContentStyled width={"100%"} pr={["", "100px"]}>
              <TextColumnItemHeadingStyled
                outline={outline}
              >
                <Heading7
                  as="div"
                  dangerouslySetInnerHTML={{ __html: heading }}
                />
              </TextColumnItemHeadingStyled>
              {ctaUrl && (
                <TextColumnItemCtaStyled>
                  <ButtonLink as="a" $contact $alt href={`mailto:${ctaUrl}`}>
                    {ctaText}
                  </ButtonLink>
                </TextColumnItemCtaStyled>
              )}
            </TextColumnItemContentStyled>
          </TextColumnItemStyled>
          <TextColumnItemStyled width={["100%", "52%"]}>
            <TextColumnItemContentStyled width={"100%"}>
              <TextColumnItemTextStyled
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </TextColumnItemContentStyled>
          </TextColumnItemStyled>
        </TextColumnStyled>
      ) : (
        <TextColumnStyled flexDirection={["column", "row"]}>
          <TextColumnItemStyled width={["100%", "57%"]}>
            <TextColumnItemContentStyled width={"100%"} pr={["", "100px"]}>
              <TextColumnItemTextStyled
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </TextColumnItemContentStyled>
          </TextColumnItemStyled>
          <TextColumnItemStyled width={["100%", "43%"]}>
            <TextColumnItemContentStyled width={"100%"}>
              <TextColumnItemHeadingStyled>
                <Heading7
                  as="div"
                  dangerouslySetInnerHTML={{ __html: heading }}
                />
              </TextColumnItemHeadingStyled>
              {ctaUrl && (
                <TextColumnItemCtaStyled>
                  <ButtonLink as="a" $contact $alt href={`mailto:${ctaUrl}`}>
                    {ctaText}
                  </ButtonLink>
                </TextColumnItemCtaStyled>
              )}
            </TextColumnItemContentStyled>
          </TextColumnItemStyled>
        </TextColumnStyled>
      )}
    </>
  );
};

export default TextColumn;

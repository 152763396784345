import React from "react";
import Container from "./../../components/Container";
import Section from "./../../components/Section";
import Inner from "./../../components/Inner";
import CtaSection from "./../../components/CtaSection";

interface Props { 
  block: { 
    heading: string 
    subheading: string
    darkButton: { 
      url: string
      title: string
    }
    lightButton: { 
      url: string 
      title: string
    }
    image: { 
      imageFile: any
    }
  }
  index: number
}

const FlexCtaSection = ({ block, index }: Props) => {
  return (
    <Container pt={["140px", "140px", "72px"]} pb={["0", "86px"]}>
      <Inner noOffsetMobile>
        <Section ptBody={["", "2.5rem"]}>
          <CtaSection
            key={index}
            heading={block.heading}
            subheading={block.subheading}
            leftButtonUrl={block.darkButton.url}
            leftButtonText={block.darkButton.title}
            rightButtonUrl={block.lightButton.url}
            rightButtonText={block.lightButton.title}
            image={["", block.image.imageFile]}
          />
        </Section>
      </Inner>
    </Container>
  );
};

export default FlexCtaSection;

import React from "react";
import styled from "styled-components";

import { Flex, FlexItem } from "./../Flex";
import Container from "./../Container";
import Infobox from "./../Infobox";
import { IFeature } from "../../interfaces/IFeature";
import { media } from "./../../common/MediaQueries";

import { Typo5 } from "./../Typography";

const UvpWrapper = styled.div`
  ${FlexItem} {
    @media ${media.phone} {
      &:last-child {
        ${Container} {
          padding-right: 0;
        }
      }
    }
  }
`;

export interface IFeatureItem {
  items: IFeature[];
}

const Uvp: React.FC<IFeatureItem> = ({ items }) => {
  return (
    <UvpWrapper>
      <Flex flexWrap={["wrap", ""]}>
        {items.map(({ heading, description, icon }, index) => (
          <FlexItem
            flex={["auto", "1"]}
            width={["100%", ""]}
            pb={["2.75rem", "0"]}
            key={icon.altText || index}
          >
            <Container width={["100%", "100%"]} pr={["", "34px", "44px"]}>
              <Infobox 
                iconSize="90px"
                heightSize="90px" 
                {...{ heading, icon }}
              >
                <Typo5
                  as="div"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </Infobox>
            </Container>
          </FlexItem>
        ))}
      </Flex>
    </UvpWrapper>
  );
};

export default Uvp;

import styled, { keyframes } from 'styled-components';

import { customMedia, media } from '../../common/MediaQueries';

const marquee = keyframes`
  from {
    transform: trnaslate(0, 0);
  }

  to {
    transform: translate(-100%, 0);
  }
`;

const ClientsListWrapperStyled = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const MobileWrapper = styled.div`
  display: flex;
  width: 100%;

  @media ${media.phone} {
    display: block;
  }
`;

interface ClientsListStyledProps {
  $hideOnDesktop?: boolean;
}

const ClientsListStyled = styled.ul<ClientsListStyledProps>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: 0;
  animation-name: ${marquee};
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @media ${media.phone} {
    animation-name: none;
    margin: 0 -0.625rem;
    flex-wrap: wrap;
    ${({ $hideOnDesktop }) => ($hideOnDesktop ? 'display: none;' : '')}
  }
`;

const ClientsListItemStyled = styled.li`
  display: flex;
  justify-content: center;
  padding: 0.625rem 2.5rem;
  width: auto;
  margin-bottom: 1rem;
  flex-shrink: 0;

  @media ${media.phone} {
    width: calc(100% / 3);
    margin-bottom: 0;
    padding: 0.625rem;
  }

  @media ${media.tablet} {
    width: calc(100% / 5);
  }
`;

const ClientsListItemMedia = styled.div`
  display: flex;
  transition: 0.25s;
  width: auto;
  font-size: 72px;
  overflow: hidden;
  outline: 0;

  &:hover {
    opacity: 0.7;
  }

  > * {
    justify-content: center;
  }
`;

const ClientsListItemMediaImg = styled.img`
  height: 1em;
  @media ${customMedia.maxPhone} {
    margin: 0 auto;
  }
`;

export {
  ClientsListWrapperStyled,
  ClientsListStyled,
  ClientsListItemStyled,
  ClientsListItemMedia,
  ClientsListItemMediaImg,
};

import React from "react";

import Container from "./../../components/Container";
import Section from "./../../components/Section";
import Inner from "./../../components/Inner";

import Clients from "./../../components/Clients";
import { Heading3 } from "./../../components/Typography";
import CLIENTS_CFG from "./../../constants/sliders/clientsCfg";

interface Props {
  block: { 
    heading: string
    clients: { image: { sourceUrl: string, altText: string } }[]
  }
}

const FlexClients = ({ block }: Props) => {
  return (
    <Container
      bg={["greyLight", "white"]}
      pt={["2.875rem", "4.5rem"]}
      pb={["36px", "4.5rem"]}
    >
      <Inner>
        <Section
          pbHead={["2rem", "2.375rem"]}
          ptBody={["2rem", "2.5rem"]}
          pbBody={["", "3rem"]}
          bodyBg={["", "greyLight"]}
          heading={
            <Heading3 as="h2" textAlign="center">
              {block.heading}
            </Heading3>
          }
          isMobileBorder
        >
          <Inner 
            isSmall 
            noOffsetMobile
            fullScreenMobile
          >
            <Clients items={block.clients} settings={CLIENTS_CFG} />
          </Inner>
        </Section>
      </Inner>
    </Container>
  );
};

export default FlexClients;

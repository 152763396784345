import React, { ReactNode } from "react";

import Container from "./../../components/Container";
import Section from "./../../components/Section";
import Inner from "./../../components/Inner";

import TextColumn from "./../../components/TextColumn";

interface Props { 
  block: { 
    heading: string
    reverseElements?: boolean;
    text: string;
    button: { 
      url: string
      title: string
    } | null
    sectionId?: string
    outline?: boolean
  }
  index: number
}

const FlexTextColumn = ({ block, index }: Props) => {
  return (
    <Container
      id={block.sectionId}
      pt={["30px", "0"]}
      pb={["80px", "198px"]}
    >
      <Inner isSmall>
        <Section>
          <TextColumn
            key={index}
            isRotated={!block.reverseElements ? true : false}
            heading={block.heading}
            text={block.text}
            ctaUrl={block.button !== null ? block.button.url : ""}
            ctaText={block.button !== null ? block.button.title : ""}
            outline={block.outline}
          />
        </Section>
      </Inner>
    </Container>
  );
};

export default FlexTextColumn;

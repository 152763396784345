import styled, { css } from 'styled-components';

import { customMedia } from './../../common/MediaQueries';

const DividerItemStyled = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grey2};
  opacity: 0.5;
  width: 100%;
`;

const DividerStyled = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.divider.normal};
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.sizes.offset};

  ${({ isMobileFullwidth }) =>
    isMobileFullwidth &&
    css`
      @media ${customMedia.maxPhone} {
        padding: 0;
      }
    `}

  ${({ isLarge }) =>
    isLarge &&
    css`
      max-width: ${({ theme }) => theme.divider.large};
    `}

    ${({ isWhite }) =>
    isWhite &&
    css`
      ${DividerItemStyled} {
        background-color: ${({ theme }) => theme.colors.white};
      }
    `}
`;

export { DividerStyled, DividerItemStyled };

import React from 'react';
import styled from 'styled-components';

const Message = styled.div`
  line-height: 1.2;
  font-size: 1.875rem;
  font-weight: 300;
  text-align: center;

  & i { 
    background-color: ${({ theme }) => theme.colors.watermelon};
    color: rgb(255, 255, 255);
    padding: 0px 4px;
    margin: 0px 2px;
    font-weight: 600; 
  }
`

interface Props { 
  text: string
}

const HighSubheading : React.FC<Props> = props => {

  const { text } = props;

  return (
    <Message dangerouslySetInnerHTML={{ __html: text }} /> 
  );
}

export default HighSubheading;
import React from "react";
import { SvgComponent } from "./../../definitions/Svg";

const ScrollArrowIcon: SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 28" {...props}>
    <g stroke="currentColor" fill="none" fillRule="evenodd">
      <rect x=".5" y=".5" width="16" height="27" rx="8" />
      <path d="M8.5 17.5v4" strokeLinecap="square" />
    </g>
  </svg>
);

export default ScrollArrowIcon;

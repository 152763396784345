import React from "react";
import Typical from "react-typical";

import { TypeWriteWrapperStyled } from "./styles";

interface IProps {
  steps: (string|number)[];
}

const TypeWrite: React.FC<IProps> = ({ steps }) => {
  return (
    <TypeWriteWrapperStyled>      
      <Typical 
        steps={steps}
        loop={Infinity}
        wrapper="span"
      />
    </TypeWriteWrapperStyled>
  );
};

export default TypeWrite;

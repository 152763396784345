import React from "react";

import {
  HeroContainerStyled,
  HeroStyled,
  HeroContentStyled,
  HeroContentHeadStyled,
  HeroContentSubheadStyled,
  HeroContentFooterStyled,
  HeroMediaStyled,
  HeroContentCtaStyled,
  HeroImagePlaceholder,
  HeroImage,
} from "./styles";

import ScrollTo from "../ScrollTo";
import DottedBg from "../DottedBg";
import ButtonLink from "../ButtonLink";
import { getCloudinarySrc, getCloudinarySrcSet } from "../../lib/get-cloudinary-src";
import { ImageFile } from "../../interfaces/IImageFile";

interface IProps {
  heading: React.ReactNode;
  subheading?: React.ReactNode;
  imageMobile: ImageFile
  imageDesktop: ImageFile
  isStrapiImage?: boolean
  scrollText?: string;
  scrollTarget?: string;
  isDottedBg?: boolean;
  bg?: string;
  ctaText?: string;
  ctaUrl?: string;
  altText?: string;
  isHeadingCentered?: boolean;
}

const Hero: React.FC<IProps> = ({
  heading,
  subheading,
  imageMobile,
  imageDesktop,
  altText,
  scrollText,
  scrollTarget,
  isDottedBg,
  isHeadingCentered,
  bg,
  ctaUrl,
  ctaText,
}) => (
  <HeroContainerStyled {...{ bg, isHeadingCentered }}>
    {isDottedBg && <DottedBg />}
    <HeroStyled>
      <HeroContentStyled>
        <HeroContentHeadStyled>
          {heading}
        </HeroContentHeadStyled>
        {!!subheading && (
          <HeroContentSubheadStyled>{subheading}</HeroContentSubheadStyled>
        )}
        {!!ctaUrl && (
          <HeroContentCtaStyled>
            <ButtonLink 
              as="a"
              $internal
              $big
              $watermelon
              href={`#${ctaUrl}`}
            >
              {ctaText}
            </ButtonLink>
          </HeroContentCtaStyled>
        )}
        {!!scrollTarget && (
          <HeroContentFooterStyled>
            <ScrollTo title={scrollText || 'Scroll Down'} {...{ scrollTarget }} />
          </HeroContentFooterStyled>
        )}
      </HeroContentStyled>
      <HeroMediaStyled>
        <HeroImagePlaceholder>
          <picture>
            <source
              srcSet={getCloudinarySrcSet(imageMobile, [200, 400, 600, 800])}
              media="(max-width: 767px)"
              sizes="(min-width: 679px) 440px, 180px"
            />
            <source
              srcSet={getCloudinarySrcSet(imageDesktop, [400, 600, 800])}
              media="(min-width: 769px)"
              sizes="(min-width: 679px) 440px, 180px"
            />
            <HeroImage
              src={getCloudinarySrc(imageDesktop, { width: 440 })}
              loading="eager"
              decoding="async"
              alt={altText}
            />
          </picture>
        </HeroImagePlaceholder>
      </HeroMediaStyled>
    </HeroStyled>
  </HeroContainerStyled>
);

export default Hero;
